<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer, type: 'pagosPHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'lista_pagos_trabajos',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :editRowBottom="{
          badgeText: 'Editar pagos trabajo',
          tipo: 'editPagosTrabajo',
        }"
        :copyRowBottom="{ badgeText: 'Copiar pagos trabajo' }"
        :exportBottom="{
          badgeText: 'Exportar pagos tabla',
          nameDoc: 'PagosTrabajos',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosPagosTrabajos($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla" v-show="mostrarTable == true">
        <vue-excel-editor
          ref="grid"
          v-if="mt"
          v-show="mostrarTable == true"
          :page="pageLengthPer"
          v-model="jsondata"
          filter-row
          :localized-label="myLabels"
          remember
          @cell-click="position"
          free-select
          @select="onSelect"
          :enterToSouth="enterAbajo"
          :cell-style="metodoColorBack"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
        >
          <vue-excel-column
            field="ID"
            label="ID"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Pago"
            label="Pago"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Cantidad"
            label="Cantidad"
            type="number"
            :validate="valid"
            :sort="sortByNumC"
          />
          <vue-excel-column
            field="Linea"
            label="Linea"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono"
            label="Teléfono"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Teléfono 2"
            label="Teléfono 2"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Valor total"
            label="Valor total"
            type="number"
            :validate="valid"
            :sort="sortByNumV"
          />
          <vue-excel-column
            field="Fecha de venta"
            label="Fecha de venta"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Estado"
            label="Estado"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Periodo"
            label="Periodo"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Notas"
            label="Notas"
            type="string"
            :change="addAnotacion"
          />
          <vue-excel-column
            field="Pagos"
            label="Pagos"
            :change="openPagos"
            type="select"
            :options="['Ver pagos']"
          />
          <vue-excel-column
            field="WhatsApp"
            label="WhatsApp"
            :change="openWA"
            type="select"
            :options="['Ir a WhatsApp']"
          />
        </vue-excel-editor>
      </v-container>
      <v-dialog v-if="dialogPagos" v-model="dialogPagos" max-width="95%">
        <registrar-pagos
          v-on:toggle="togglePagos($event)"
          :cliente="nameCard"
          :listaPagos="listaPagos"
          :valorAcordado="valorAcordado"
          :valorRecaudado="valorRecaudado"
          :valorRestante="valorRestante"
          :tipo="'pendientes'"
          :trabajo="true"
        ></registrar-pagos>
      </v-dialog>
      <!-- dialog de WA -->
      <v-dialog v-if="dialogW" v-model="dialogW" max-width="30%">
        <whats-app
          v-on:eventosWa="eventosWApp"
          :name="nameCard"
          :optionsW="optionsW"
        ></whats-app>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import WhatsApp from "./component/WhatsApp.vue";
// import RegistrarPagos from "./component/RegistrarPagos.vue";
import infoSelected from "./Tools/itemSelected";
import ToolBarNav from "./component/ToolBarNav.vue";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],
  components: {
    ToolBarNav,
    WhatsApp: () =>
      import(/* webpackChunkName: "WhatsApp"  */ "./component/WhatsApp.vue"),
    RegistrarPagos: () =>
      import(
        /* webpackChunkName: "RegistrarPagos"  */ "./component/RegistrarPagos.vue"
      ),
    // toolBar,
  },
  data() {
    return {
      mt: false,
      textoActionToolBar: [],
      dialogW: false,
      // estadoP: [],
      mostrarTable: false,
      dialogPagos: false,
      nameCard: null,
      listaPagos: {},
      menu: false,
      jsondata: [],
      valorAcordado: null,
      valorRestante: null,
      valorRecaudado: null,
      valorCuota: null,
      dates: [],
      pageLengthPer: this.$store.state.pagosTHeigth,
      listaColumnas: [],
      columnasMostrar: [],
      editarColumnas: false,
      optionsW: null,
      dataWA: [],
      rowWA: null,
      // cant: [],
      dataPagos: [],
      onSelected: null,
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  async created() {
    await this.cargueColors();
  },

  mounted() {
    this.buscar();
    document.title = "Pagos Trabajos";
  },

  methods: {
    buscar() {
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      this.jsondata = [];
      this.mt = false;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "trabajos");
          axios.post(host + "/pagos", fd).then((result) => {
            let data = result.data;
            if (data.text == "OK") {
              this.buscando = false;
              this.pageLength = data.lista.length;
              for (var i = 0; i < data.lista.length; i++) {
                var v = Intl.NumberFormat("de-DE").format(
                  data.lista[i]["Valor total"]
                );
                var c = Intl.NumberFormat("de-DE").format(
                  data.lista[i]["Cantidad"]
                );
                data.lista[i]["Cantidad"] = c;
                data.lista[i]["Valor total"] = v;
                this.dataWA.push({
                  id: data.lista[i]["_id"],
                  data: data.lista[i]["WhatsApp"],
                });
                data.lista[i]["WhatsApp"] =
                  data.lista[i]["WhatsApp"] == "null" ? "-" : "";
                data.lista[i]["Fecha de venta"] = this.$moment(
                  data.lista[i]["Fecha de venta"]
                ).format("YYYY/MM/DD - HH:mm:ss");
                data.lista[i]["Fecha de pago"] = this.$moment(
                  data.lista[i]["Fecha de pago"]
                ).format("YYYY/MM/DD - HH:mm:ss");
                this.dataPagos.push({
                  id: data.lista[i]["_id"],
                  data: data.lista[i]["Pagos"],
                });
                data.lista[i]["Pagos"] =
                  data.lista[i]["Pagos"] == "null" ? "-" : "";
              }
              this.jsondata = data.lista;
              if (this.jsondata.length == 0) {
                this.$alert("No existen pagos pendientes para estas fechas");
              } else {
                this.mostrarTable = true;
                this.listaColumnas = result.data.visibilidad;
                for (var k = 0; k < this.listaColumnas.length; k++) {
                  if (this.listaColumnas[k]["valor"] == true) {
                    this.columnasMostrar.push(this.listaColumnas[k]);
                  }
                }
                this.cargueCol();
              }
            } else {
              this.$alert(result.data.text);
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.buscando = false;
          this.$alert("Error " + error.message);
        });
    },

    openPagos(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.dialogPagos = true;
      this.listaPagos = row;
      this.valorAcordado = row.valorAcordado;
      this.valorRestante = row.saldoRestante;
      this.valorRecaudado = row.valorRecaudado;
      this.nameCard = row.nombre;
    },

    position(rowPos) {
      this.rowWA = rowPos.rowPos;
    },

    openWA(oldVal, newVal, row, field) {
      this.$refs.grid.inputBoxBlur();
      console.log(field);
      this.dialogW = true;
      this.nameCard = row.nombre;
      const resObject = this.dataWA.find((item) => item.id === row._id);
      this.optionsW = resObject.data;
    },

    closeWA() {
      this.dialogW = false;
      this.rowWA = null;
      this.nameCard = null;
    },

    // orden por cantidad
    sortByNumC(a, b) {
      if (a["cantidad"] > b["cantidad"]) {
        return 1;
      }
      if (a["cantidad"] < b["cantidad"]) {
        return -1;
      }
      return 0;
    },

    sortByNumV(a, b) {
      if (a["valorAcordado"] > b["valorAcordado"]) {
        return 1;
      }
      if (a["valorAcordado"] < b["valorAcordado"]) {
        return -1;
      }
      return 0;
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      this.rigthClick = false;
    },

    metodoColor(row, col) {
      var verde = ["ENVIADO A CLIENTE", "FINALIZADO CORREO"];
      var rojos = ["CANCELADO", "NUNCA ENVIADO", "MAL MIGRADO"];
      var moradoNuevo = ["NUEVO"];
      var azul = ["FINALIZADO"];
      switch (col.name) {
        case "Estado":
          if (rojos.includes(row["Estado"])) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (verde.includes(row["Estado"])) {
            return {
              "background-color": "#34a853",
            };
          }
          if (moradoNuevo.includes(row["Estado"])) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (azul.includes(row["Estado"])) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        default:
          break;
      }
      return {};
    },

    addAnotacion(newVal, oldVal, row, field) {
      console.log(field, oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", " registrar_nota");
          fd.append("pagoId", row["_id"]);
          fd.append("nota", newVal.trim());
          axios.post(host + "/pagos", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("No se pudo agregar la anotación");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    // EVENTOS
    eventosPagosTrabajos(d) {
      if (d == "refresh" || d == "edit") {
        this.buscar();
      }
      if (d == "editPagosTrabajo") {
        this.openPagos("", "", this.onSelected.field);
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    togglePagos(x) {
      if (x == true) {
        this.buscar();
        this.dialogPagos = false;
      }
      if (x == false) {
        this.dialogPagos = false;
      }
    },

    eventosWApp(d) {
      if (d == false) this.dialogW = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-money {
  outline: none !important;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  padding: 4px 0px;
  margin-top: 18px;
  color: grey;
  font-size: 18px;
  width: 100%;
}
</style>